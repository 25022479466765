<template>
  <div>MASTER

            </div>
</template>

<script>

export default{

  async mounted(){
    this.$hideLoader();
  }
}
</script>

